import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'
import axios from '@/plugins/axios'
import { Payload } from 'vue/interfaces'

@Module({ namespaced: true })
export default class GlobalModule extends VuexModule {
  public loading = false
  public loadingMenu = false
  public signalRenderMenu = false

  get getLoading() {
    return this.loading
  }

  get getLoadingMenu() {
    return this.loadingMenu
  }

  get watchSignalRenderMenu() {
    return this.signalRenderMenu
  }

  // Mutation
  @Mutation setLoading(payload: boolean) {
    this.loading = payload
  }

  @Mutation setLoadingMenu(payload: boolean) {
    this.loadingMenu = payload
  }

  @Mutation setSignalRenderMenu() {
    this.signalRenderMenu = !this.signalRenderMenu
  }

  // Action
  @Action({ rawError: true }) async loadImage(image: Payload) {
    try {
      this.context.commit('setLoading', true)

      const formdata = new FormData()
      formdata.append('file', image as Blob)

      const res = await axios.post('/files/do_upload', formdata)
      this.context.commit('setLoading', false)
      return res.data
    } catch (error) {
      this.context.commit('setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async deleteImage(id: string) {
    try {
      this.context.commit('setLoading', true)

      const formdata = new FormData()
      formdata.append('file_id', id)

      const res = await axios.post('/files/do_delete', formdata)
      if (res.data.error) {
        throw new Error('Удаление файла не произошло!')
      }
      this.context.commit('setLoading', false)
    } catch (error) {
      this.context.commit('setLoading', false)
      throw error
    }
  }

  // Cсылка на API:
  // http://api.sputnik.ru/maps/geocoder/
  @Action({ rawError: true }) async getCordByAdress(adress: string) {
    try {
      this.context.commit('setLoading', true)

      const formdata = new FormData()
      formdata.append('address', adress)

      const res = await axios.post('/prices/get_sputnik_address', formdata)
      // const res = await axios.get('http://search.maps.sputnik.ru/search?q=' + adress)

      console.log(res.data)

      if (res.data.length === 0) {
        throw new Error('Определение не сработало, возможно в адресе есть ошибка или его не существует')
      }

      this.context.commit('setLoading', false)
      return res.data[0]
    } catch (error) {
      this.context.commit('setLoading', false)
      throw error
    }
  }

  // Cсылка на API:
  // https://dadata.ru/api/geolocate/
  // Просто у спутника не работает обратное декодирование на данный момент, на время подключил этот сервис
  @Action({ rawError: true }) async getAdressByCords(cords: {lat: number; lon: number}) {
    try {
      this.context.commit('setLoading', true)

      // API просит присылать JSON
      const bodyJson = {
        lat: cords.lat,
        lon: cords.lon
      }

      const APIHeaders = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token c4d8f89858158e1e76d494117bd68a91a33247b6'
      }

      const res = await axios.post('https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address', bodyJson, { headers: APIHeaders })
      if (res.data.suggestions && res.data.suggestions.length > 0) {
        this.context.commit('setLoading', false)
        return res.data.suggestions[0]
      } else {
        throw new Error('Определение по координатам не сработало, DADATA не может определить адрес')
      }
    } catch (error) {
      this.context.commit('setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async getAddressesList(address: string) {
    try {
      const bodyJson = {
        query: address
      }

      const APIHeaders = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: 'Token c4d8f89858158e1e76d494117bd68a91a33247b6'
      }

      const res = await axios.post('https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address', bodyJson, { headers: APIHeaders })
      if (res.data.suggestions && res.data.suggestions.length > 0) {
        return res.data.suggestions.map((address) => {
          return address.value
        })
      }
    } catch (error) {
      console.log(error)
      throw error
    }
  }

  @Action({}) signalRenderMenuNow() {
    this.context.commit('setSignalRenderMenu')
  }
}
