import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import axios from '@/plugins/axios'
import global from '@/store'

import { Payload } from 'vue/interfaces'

const { ErrorObject } = require('../../utilities/ErrorObject')

@Module({ namespaced: true })
export default class ClientsModule extends VuexModule {
  public clients: Array<Payload> = []
  public managersNames: Array<Payload> = []
  public mainPageClients: Array<Payload> = []

  get getManagers() {
    return this.managersNames
  }

  get getClients() {
    return this.clients
  }

  get getMainPageClients() {
    return this.mainPageClients
  }

  // Mutation
  @Mutation setClients(payload: Array<Payload>) {
    this.clients = payload
  }

  @Mutation setMainPageClients(payload: Array<Payload>) {
    this.mainPageClients = payload
  }

  @Mutation setManagers(payload: Array<Payload>) {
    this.managersNames = payload
  }

  @Action({ rawError: true }) async changeFavourite(payload: {clientId: string | number; priceId: string | number}) {
    try {
      global.commit('GlobalModule/setLoading', true)
      console.log(payload)
      const formdata = new FormData()
      formdata.append('client_id', payload.clientId.toString())
      formdata.append('price_id', payload.priceId.toString())
      const res = await axios.post('/clients/do_change_favourite', formdata)

      if (!res.data.success) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async requestMainPageClients(params = {}) {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get('/main_page/get_clients', {
        params
      })

      global.commit('GlobalModule/setLoading', false)

      if (res.data.error) {
        throw new Error(res.data.error)
      }

      if (!res.data.clients) {
        throw new Error('Произошла непредвиденная ошибка на сервере!')
      }

      return res.data
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async requestClients() {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get('/clients/get_all')
      this.context.commit('setClients', res.data.clients)
      // console.log(res.data.clients)
      //   const managers = res.data.prices.map((price) => {
      //     return price.manager_name
      //   })
      this.context.commit('setManagers', res.data.managers)
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async getClientPageData(id: number | string) {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get(`/clients/get_one_client_data/${id}`)
      if (!res.data.client) {
        throw new Error('Технические данные для страницы не были получены, ошибка на сервере. Обратитесь к разработчикам.')
      }
      global.commit('GlobalModule/setLoading', false)
      return res.data
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async updateClient(payload: Payload) {
    try {
      global.commit('GlobalModule/setLoading', true)

      const emails = payload.emails ? JSON.stringify(payload.emails.replaceAll(' ', '').split(',')) : ''
      const phones = payload.phones ? JSON.stringify(payload.phones.replaceAll(' ', '').split(',')) : ''

      const formdata = new FormData()
      formdata.append('client_id', payload.client_id)
      formdata.append('firstname', payload.firstname)
      formdata.append('lastname', payload.lastname)
      formdata.append('middlename', payload.middlename)
      formdata.append('emails', emails)
      formdata.append('phones', phones)
      formdata.append('city', payload.city)
      formdata.append('user_id', payload.user_id)
      formdata.append('demonstrator_id', payload.demonstrator_id)
      formdata.append('status', payload.status)
      formdata.append('status_date', payload.status_date)
      formdata.append('show_date', payload.show_date)
      formdata.append('comment', payload.comment)
      formdata.append('client_type', payload.client_type)
      formdata.append('client_source', payload.client_source)
      formdata.append('client_unique', payload.client_unique)
      formdata.append('main_object_id', payload.main_object_id)

      if (payload.price_id) {
        formdata.append('price_id', payload.price_id)
      }

      const res = await axios.post('/clients/do_edit', formdata)
      if (!res.data.success) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async deleteClient(id: string | number) {
    try {
      global.commit('GlobalModule/setLoading', true)

      const formdata = new FormData()
      formdata.append('client_id', id.toString())
      const res = await axios.post('/clients/do_delete', formdata)

      if (!res.data.success) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async createClient(payload: Payload) {
    try {
      const emails = payload.emails ? JSON.stringify(payload.emails.replaceAll(' ', '').split(',')) : ''
      const phones = payload.phones ? JSON.stringify(payload.phones.replaceAll(' ', '').split(',')) : ''

      console.log(payload)

      const formdata = new FormData()
      formdata.append('firstname', payload.firstname)
      formdata.append('lastname', payload.lastname)
      formdata.append('middlename', payload.middlename)
      formdata.append('emails', emails)
      formdata.append('phones', phones)
      formdata.append('city', payload.city)
      formdata.append('user_id', payload.user_id ?? 0)
      formdata.append('demonstrator_id', payload.demonstrator_id ?? 0)
      formdata.append('status', payload.status)
      formdata.append('comment', payload.comment)

      const res = await axios.post('/clients/do_create', formdata)
      if (!res.data.success) {
        throw new ErrorObject(res.data)
      }
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }
}
