import Noty from 'noty'

const noty = (type: 'alert' | 'success' | 'warning' | 'error' | 'info' | 'information' | undefined, text: string, timeout: number | undefined) => {
  return new Noty({
    type,
    text,
    timeout
  })
}
export default noty
