import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

import axios from '@/plugins/axios'
import global from '@/store'
import { Payload } from 'vue/interfaces'

@Module({ namespaced: true })
export default class AccessRightsModule extends VuexModule {
  public accessrightsList: Array<object> = []
  public menuList: Array<object> = []

  get getAccessRights() {
    return this.accessrightsList
  }

  get getMenuList() {
    return this.menuList
  }

  // Mutation
  @Mutation setAccessRights(payload: Array<object>) {
    this.accessrightsList = payload
  }

  @Mutation setMenuList(payload: Array<object>) {
    this.menuList = payload
  }

  // Action
  @Action({ rawError: true }) async requestAccessRights() {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get('/access_rights/get_all')
      this.context.commit('setAccessRights', res.data.access_rights)
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async getPageDataForModule() {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get('/access_rights/get_page_data')
      global.commit('UserModule/setUserGroups', res.data.users_groups)
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async saveAccessRights(payload: Payload) {
    try {
      global.commit('GlobalModule/setLoading', true)
      const formdata = new FormData()
      formdata.append('data', payload.data)
      const res = await axios.post('/access_rights/do_edit', formdata)
      if (res.data.error) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async getMenuListByPermission(payload: Payload) {
    try {
      global.commit('GlobalModule/setLoadingMenu', true)
      const formdata = new FormData()
      formdata.append('data', JSON.stringify(payload.menuList))
      const res = await axios.post('/access_rights/do_format_menu', formdata)
      this.context.commit('setMenuList', res.data.menu)
      global.commit('GlobalModule/setLoadingMenu', false)
    } catch (error) {
      global.commit('GlobalModule/setLoadingMenu', false)
      throw error
    }
  }
}
