import Vue from 'vue'
import Bootstrap from './Bootstrap.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Noty from './plugins/noty'
import VueMeta from 'vue-meta'
import DatetimePicker from 'vuetify-datetime-picker'

Vue.use(DatetimePicker)
Vue.use(VueMeta)
Vue.prototype.$noty = Noty

// Если собираете проект, переключите режим на false
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(Bootstrap)
}).$mount('#app')
