import { VuexModule, Module, Action } from 'vuex-module-decorators'
import axios from '@/plugins/axios'
import global from '@/store'

// import { Payload } from 'vue/interfaces'

@Module({ namespaced: true })
export default class CallsModule extends VuexModule {
  //   public calls: Array<Payload> = []

  //   get getCalls() {
  //     return this.calls
  //   }

  //   @Mutation setCalls(payload: Array<Payload>) {
  //     this.calls = payload
  //   }

  @Action({ rawError: true }) async requestPaginationData(page) {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get('/calls/get_calls?skip=' + (page - 1))
      if (res.data.error) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
      return res.data
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }
}
