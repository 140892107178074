import { VuexModule, Module, Mutation, Action } from 'vuex-module-decorators'

import axios from '@/plugins/axios'
import global from '@/store'
import { Payload } from 'vue/interfaces'

@Module({ namespaced: true })
export default class SettingsModule extends VuexModule {
  public settingsList: Array<object> = []

  get getSettings() {
    return this.settingsList
  }

  // Mutation
  @Mutation setSettings(payload: Array<object>) {
    this.settingsList = payload
  }

  // Action
  @Action({ rawError: true }) async requestSettings() {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get('/settings/get_all')
      this.context.commit('setSettings', res.data.settings)
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async saveSetting(payload: Payload) {
    try {
      global.commit('GlobalModule/setLoading', true)

      const formdata = new FormData()
      formdata.append('setting_id', payload.id)
      formdata.append('title', payload.title)
      formdata.append('content', payload.content)

      let res: any
      if (payload.id !== '0') {
        res = await axios.post('/settings/do_edit', formdata)
      } else {
        res = await axios.post('/settings/do_create', formdata)
      }

      if (res.data.error) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }
}
