import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import axios from '@/plugins/axios'
import global from '@/store'

import { Payload } from 'vue/interfaces'

@Module({ namespaced: true })
export default class AdsModule extends VuexModule {
  public prices: Array<Payload> = []

  get getPrices() {
    return this.prices
  }

  @Mutation setPriceObjects(payload: Array<Payload>) {
    this.prices = payload
  }

  @Action({ rawError: true }) async requestPriceObjects() {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get('/ads/get_all_prices')
      this.context.commit('setPriceObjects', res.data.prices)
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async getExpenses(data) {
    try {
      global.commit('GlobalModule/setLoading', true)
      let url = `/ads/get_expenses/${data.id}?start=${data.dateStart}&end=${data.dateEnd}`
      if (!data.dateEnd) {
        url = `/ads/get_expenses/${data.id}?start=${data.dateStart}`
      }

      const res = await axios.get(url)
      if (res.data.error) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
      return res.data
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async changeAdStatus(id) {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get(`/ads/do_change_status/${id}`)
      global.commit('GlobalModule/setLoading', false)
      return res.data
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async getAll() {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get('/ads/get_all')
      if (!res.data.yandex) {
        throw new Error('Технические данные для страницы не были получены, ошибка на сервере. Обратитесь к разработчикам.')
      }
      global.commit('GlobalModule/setLoading', false)
      return res.data
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async getOne(id: number | string) {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get(`/ads/get_one/${id}`)
      if (!res.data.data && !res.data.date) {
        throw new Error('Технические данные для страницы не были получены, ошибка на сервере. Обратитесь к разработчикам.')
      }
      global.commit('GlobalModule/setLoading', false)
      return res.data
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async getOneUpdate(id: number | string) {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get(`/ads/get_one_update/${id}`)
      if (!res.data.data && !res.data.date) {
        throw new Error('Технические данные для страницы не были получены, ошибка на сервере. Обратитесь к разработчикам.')
      }
      global.commit('GlobalModule/setLoading', false)
      return res.data
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }
}
