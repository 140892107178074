import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import axios from '@/plugins/axios'
import global from '@/store'

import { Payload } from 'vue/interfaces'

@Module({ namespaced: true })
export default class PricesModule extends VuexModule {
  public prices: Array<Payload> = []
  public myprices: Array<Payload> = []
  public managersNames: Array<Payload> = []

  get getManagers() {
    return this.managersNames
  }

  get getMyPrices() {
    return this.prices
  }

  get getPrices() {
    return this.prices
  }

  // Mutation
  @Mutation setPriceObjects(payload: Array<Payload>) {
    this.prices = payload
  }

  @Mutation setMyPriceObjects(payload: Array<Payload>) {
    this.prices = payload
  }

  @Mutation setManagers(payload: Array<Payload>) {
    this.managersNames = payload
  }

  @Action({ rawError: true }) async getEditPageData() {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get('/prices/get_page_data')
      if (!res.data.properties && !res.data.users) {
        throw new Error('Технические данные для страницы не были получены, ошибка на сервере. Обратитесь к разработчикам.')
      }
      global.commit('GlobalModule/setLoading', false)
      return res.data
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async getCreatePageData(id: number | string) {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get(`/prices/get_page_data_with_object/${id}`)
      if (!res.data.properties && !res.data.users && !res.data.price) {
        throw new Error('Технические данные для страницы не были получены, ошибка на сервере. Обратитесь к разработчикам.')
      }
      global.commit('GlobalModule/setLoading', false)
      return res.data
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async requestMyPriceObjects() {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get('/myprices/get_all')
      this.context.commit('setMyPriceObjects', res.data.prices)
      // const managers = res.data.prices.map((price) => {
      //   return price.users_titles
      // })
      // this.context.commit('setManagers', Array.from(new Set(managers)))
      this.context.commit('setManagers', res.data.managers)
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async requestPriceObjects() {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get('/prices/get_all')
      this.context.commit('setPriceObjects', res.data.prices)
      // const managers = res.data.prices.map((price) => {
      //   return price.users_titles
      // })
      // this.context.commit('setManagers', Array.from(new Set(managers)))
      this.context.commit('setManagers', res.data.managers)
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async updateObject(payload: Payload) {
    try {
      global.commit('GlobalModule/setLoading', true)

      const formdata = new FormData()
      formdata.append('price_id', payload.id)
      formdata.append('images', JSON.stringify(payload.images))
      formdata.append('name', payload.name.trim() === '' ? 'Безымянный' : payload.name)
      formdata.append('cords', payload.cords)
      formdata.append('adress', payload.adress)
      formdata.append('type_id', payload.typeId)
      // formdata.append('users_ids', payload.usersIds)
      formdata.append('users_ids', payload.usersIds ? JSON.stringify(payload.usersIds) : '')
      formdata.append('object_status', payload.objStatusId)
      formdata.append('sale_status', payload.saleStatusId)
      formdata.append('price', payload.price)
      formdata.append('cad_number', payload.cadNumber)
      formdata.append('metro', payload.metro)
      formdata.append('city', payload.city)
      formdata.append('common_meters', payload.commonMeters)
      formdata.append('kitchen_meters', payload.kitchenMeters)
      formdata.append('place_meters', payload.placeMeters)
      formdata.append('floors', payload.floors)
      formdata.append('object_state', payload.objectState)
      formdata.append('live_meters', payload.liveMeters)
      formdata.append('text', payload.text)

      formdata.append('locality', payload.locality)
      formdata.append('additional_expenses', payload.additionalExpenses)
      formdata.append('aspv', payload.aspv)
      formdata.append('territory_document', payload.territoryDocument)
      formdata.append('territory_fact', payload.territoryFact)
      formdata.append('size_house', payload.sizeHouse)
      formdata.append('foundation', payload.foundation)
      formdata.append('wall_insulation', payload.wallInsulation)
      formdata.append('home', payload.home)
      formdata.append('documents_house', payload.documentsHouse)
      formdata.append('documents_grounds', payload.documentsGrounds)
      formdata.append('documents_gas', payload.documentsGas)
      formdata.append('documents_electro', payload.documentsElectro)
      formdata.append('gas', payload.gas)
      formdata.append('electro', payload.electro)
      formdata.append('water', payload.water)
      formdata.append('sewerage', payload.sewerage)
      formdata.append('facade', payload.facade)
      formdata.append('internal_facing', payload.internalFacing)
      formdata.append('transport_reach', payload.transportReach)
      formdata.append('infrastructure', payload.infrastructure)
      formdata.append('special_conditions', payload.specialConditions)
      formdata.append('well_connected', payload.wellConnected)
      formdata.append('stock', payload.stock)
      formdata.append('meter_number', payload.meterNumber)
      formdata.append('account_number', payload.accountNumber)
      formdata.append('keys_house', payload.keysHouse)
      formdata.append('curator', payload.curator)
      formdata.append('owner', payload.owner)
      formdata.append('scheme', payload.scheme)

      formdata.append('clients_ids', payload.clientsIds ? JSON.stringify(payload.clientsIds) : '')

      const res = await axios.post('/prices/do_edit', formdata)
      if (!res.data.success) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async addToMyPrices(id: string | number) {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get(`/prices/get_add_to_my_prices/${id}`)
      global.commit('GlobalModule/setLoading', false)
      return res.data
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async deleteFromMyPrices(id: string | number) {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get(`/prices/get_delete_from_my_prices/${id}`)
      global.commit('GlobalModule/setLoading', false)
      return res.data
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async getPresentationMyPrices(payload: Payload) {
    try {
      global.commit('GlobalModule/setLoading', true)

      const formdata = new FormData()
      formdata.append('price_id', payload.priceId)
      formdata.append('address', payload.address)
      formdata.append('price', payload.price)
      formdata.append('km', payload.km)
      formdata.append('kvm', payload.kvm)
      formdata.append('kvmM', payload.kvmM)
      formdata.append('sotok', payload.sotok)
      formdata.append('material', payload.material)
      formdata.append('communications', payload.communications)
      formdata.append('transport', payload.transport)
      formdata.append('infrastructure', payload.infrastructure)
      formdata.append('image', payload.image)

      const res = await axios.post('/myprices/get_presentation', formdata)

      if (!res.data.success) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
      return res
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async getPresentation(payload: Payload) {
    try {
      global.commit('GlobalModule/setLoading', true)

      const formdata = new FormData()
      formdata.append('price_id', payload.priceId)
      formdata.append('address', payload.address)
      formdata.append('price', payload.price)
      formdata.append('km', payload.km)
      formdata.append('kvm', payload.kvm)
      formdata.append('kvmM', payload.kvmM)
      formdata.append('sotok', payload.sotok)
      formdata.append('material', payload.material)
      formdata.append('communications', payload.communications)
      formdata.append('transport', payload.transport)
      formdata.append('infrastructure', payload.infrastructure)
      formdata.append('image', payload.image)

      const res = await axios.post('/prices/get_presentation', formdata)

      if (!res.data.success) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
      return res
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async deleteObject(id: string | number) {
    try {
      global.commit('GlobalModule/setLoading', true)

      const formdata = new FormData()
      formdata.append('price_id', id.toString())
      const res = await axios.post('/prices/do_delete', formdata)

      if (!res.data.success) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async deleteArrayObject(arr: Array<string | number>) {
    try {
      global.commit('GlobalModule/setLoading', true)

      const formdata = new FormData()
      formdata.append('ids', JSON.stringify(arr))
      const res = await axios.post('/prices/do_delete_array', formdata)

      if (!res.data.success) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async createObject(payload: Payload) {
    try {
      global.commit('GlobalModule/setLoading', true)

      const formdata = new FormData()
      formdata.append('images', JSON.stringify(payload.images))
      formdata.append('cords', payload.cords)
      formdata.append('name', payload.name.trim() === '' ? 'Безымяный' : payload.name)
      formdata.append('adress', payload.adress)
      formdata.append('type_id', payload.typeId)
      formdata.append('users_ids', payload.usersIds)
      formdata.append('object_status', payload.objStatusId)
      formdata.append('sale_status', payload.saleStatusId)
      formdata.append('price', payload.price)
      formdata.append('cad_number', payload.cadNumber)
      formdata.append('metro', payload.metro)
      formdata.append('city', payload.city)
      formdata.append('common_meters', payload.commonMeters)
      formdata.append('kitchen_meters', payload.kitchenMeters)
      formdata.append('place_meters', payload.placeMeters)
      formdata.append('floors', payload.floors)
      formdata.append('object_state', payload.objectState)
      formdata.append('live_meters', payload.liveMeters)
      formdata.append('text', payload.text)

      formdata.append('locality', payload.locality)
      formdata.append('additional_expenses', payload.additionalExpenses)
      formdata.append('aspv', payload.aspv)
      formdata.append('territory_document', payload.territoryDocument)
      formdata.append('territory_fact', payload.territoryFact)
      formdata.append('size_house', payload.sizeHouse)
      formdata.append('foundation', payload.foundation)
      formdata.append('wall_insulation', payload.wallInsulation)
      formdata.append('home', payload.home)
      formdata.append('documents_house', payload.documentsHouse)
      formdata.append('documents_grounds', payload.documentsGrounds)
      formdata.append('documents_gas', payload.documentsGas)
      formdata.append('documents_electro', payload.documentsElectro)
      formdata.append('gas', payload.gas)
      formdata.append('electro', payload.electro)
      formdata.append('water', payload.water)
      formdata.append('sewerage', payload.sewerage)
      formdata.append('facade', payload.facade)
      formdata.append('internal_facing', payload.internalFacing)
      formdata.append('transport_reach', payload.transportReach)
      formdata.append('infrastructure', payload.infrastructure)
      formdata.append('special_conditions', payload.specialConditions)
      formdata.append('well_connected', payload.wellConnected)
      formdata.append('stock', payload.stock)
      formdata.append('meter_number', payload.meterNumber)
      formdata.append('account_number', payload.accountNumber)
      formdata.append('keys_house', payload.keysHouse)
      formdata.append('curator', payload.curator)
      formdata.append('owner', payload.owner)
      formdata.append('scheme', payload.scheme)

      formdata.append('clients_ids', payload.clientsIds ? JSON.stringify(payload.clientsIds) : '')

      const res = await axios.post('/prices/do_create', formdata)
      if (!res.data.success) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async loadExcelFileToServer(file: object) {
    try {
      global.commit('GlobalModule/setLoading', true)

      const formdata = new FormData()
      formdata.append('file', file as Blob)

      const res = await axios.post('/prices/do_sync_objects', formdata)

      if (!res.data.success) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
      return res
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async unloadExcelFileFromServer(ids = null) {
    try {
      global.commit('GlobalModule/setLoading', true)

      const formdata = new FormData()
      formdata.append('ids', ids ?? '')

      const res = await axios.post('/prices/do_unload_objects', formdata)

      if (!res.data.success) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
      return res
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }
}
