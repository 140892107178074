import { Route } from 'vue/interfaces'

export const routes: Array<Route> = [
  // Own page
  {
    path: '',
    component: () => import('../layouts/Main.vue'),
    categories: [
      {
        title: 'Прайс',
        icon: 'mdi-briefcase-download-outline',
        altIcon: 'mdi-chevron-down',
        model: true,
        id: 3
      },
      {
        title: 'Реклама',
        icon: 'mdi-sale',
        altIcon: 'mdi-chevron-down',
        model: true,
        id: 2
      },
      {
        title: 'Пользователи',
        icon: 'mdi-account-group',
        altIcon: 'mdi-chevron-down',
        model: true,
        id: 4
      },
      {
        title: 'Система',
        icon: 'mdi-chart-donut',
        altIcon: 'mdi-chevron-down',
        model: true,
        id: 1
      }
    ],
    children: [
      {
        path: '/',
        name: 'home',
        title: 'Воронка',
        icon: 'mdi-home-outline',
        component: () => import('../views/Home.vue')
      },
      {
        path: '/prices',
        name: 'prices',
        title: 'Все объекты',
        icon: 'mdi-home-group',
        categoryId: 3,
        component: () => import('../views/prices/SummaryPrices.vue')
      },
      {
        path: '/myprices',
        name: 'myprices',
        title: 'Мои объекты',
        icon: 'mdi-home-group-plus',
        categoryId: 3,
        component: () => import('../views/prices/SummaryMyPrices.vue')
      },
      {
        path: '/clients',
        name: 'clients',
        title: 'Клиенты',
        icon: 'mdi-account-group-outline',
        categoryId: 3,
        component: () => import('../views/clients/SummaryClients.vue')
      },
      {
        path: '/clients/:id',
        name: 'clients-edit',
        menu: false,
        component: () => import('../views/clients/EditClients.vue')
      },
      // {
      //   path: '/sale',
      //   name: 'sale',
      //   title: 'Сводная информация',
      //   icon: 'mdi-message-bulleted',
      //   categoryId: 2,
      //   component: () => import('../views/sale/SummarySale.vue')
      // },
      {
        path: '/ads',
        name: 'ads',
        title: 'Эффективность площадок',
        icon: 'mdi-poll',
        categoryId: 2,
        component: () => import('../views/ads_statistic/SummaryAds.vue')
      },
      {
        path: '/ads/:id',
        name: 'ads-one',
        title: 'Эффективность площадок',
        menu: false,
        component: () => import('../views/ads_statistic/PriceAds.vue')
      },
      {
        path: '/prices/create',
        name: 'prices-create',
        menu: false,
        component: () => import('../views/prices/CreatePrices.vue')
      },
      {
        path: '/prices/edit/:id',
        name: 'prices-edit',
        menu: false,
        component: () => import('../views/prices/EditPrices.vue')
      },
      {
        path: '/users',
        name: 'users',
        title: 'Список пользователей',
        icon: 'mdi-account-outline',
        categoryId: 4,
        component: () => import('../views/system/Users.vue')
      },
      {
        path: '/users_groups',
        name: 'users_groups',
        title: 'Группы пользователей',
        icon: 'mdi-account-key-outline',
        categoryId: 4,
        component: () => import('../views/system/UsersGroups.vue')
      },
      {
        path: '/schedules',
        name: 'schedules',
        title: 'Отдел кадров',
        icon: 'mdi-book-open',
        categoryId: 4,
        component: () => import('../views/schedules/Schedules.vue')
      },
      {
        path: '/calls',
        name: 'calls',
        title: 'Телефония',
        icon: 'mdi-phone',
        categoryId: 2,
        component: () => import('../views/calls/SummaryCalls.vue')
      },
      {
        path: '/access_rights',
        name: 'accessrights',
        title: 'Права доступа',
        icon: 'mdi-fingerprint',
        categoryId: 1,
        component: () => import('../views/system/AccessRights.vue')
      },
      {
        path: '/settings',
        name: 'settings',
        title: 'Настройки',
        icon: 'mdi-cog-outline',
        categoryId: 1,
        component: () => import('../views/system/Settings.vue')
      }
    ]
  },
  // Common page
  {
    path: '',
    component: () => import('../layouts/FullPage.vue'),
    children: [
      {
        path: '/login',
        name: 'login',
        menu: false,
        component: () => import('../views/Login.vue')
      },
      {
        path: '/404',
        name: 'error',
        menu: false,
        component: () => import('../views/Error404.vue')
      },
      {
        path: '/403',
        name: 'error403',
        menu: false,
        component: () => import('../views/Error403.vue')
      }
    ]
  },

  {
    path: '*',
    redirect: '/404'
  }
]
