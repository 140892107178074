import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import { Payload } from 'vue/interfaces'

import global from '@/store'
import axios from '@/plugins/axios'

@Module({ namespaced: true })
export default class UserModule extends VuexModule {
  public user = {}
  public userList: Array<object> = []
  public userGroups: Array<object> = []

  get userInfo() {
    return this.user
  }

  get getUsers() {
    return this.userList
  }

  get getUserGroups() {
    return this.userGroups
  }

  // Mutation
  @Mutation setUserInfo(payload: object) {
    this.user = payload
  }

  @Mutation setUsers(payload: Array<object>) {
    this.userList = payload
  }

  @Mutation setUserGroups(payload: Array<object>) {
    this.userGroups = payload
  }

  // Action
  @Action({ rawError: true }) async login(payload: Payload) {
    try {
      global.commit('GlobalModule/setLoading', true)

      const formdata = new FormData()
      formdata.append('login', payload.login)
      formdata.append('password', payload.password)

      const res = await axios.post('/login/do_login', formdata)
      global.commit('GlobalModule/setLoading', false)
      if (!res.data.error) {
        const user = res.data.additional.user
        this.context.commit('setUserInfo', user)
      } else {
        throw new Error(res.data.error)
      }
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async requestUserGroups() {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get('/users_groups/get_all')
      this.context.commit('setUserGroups', res.data.users_groups)
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async requestUsersPageData() {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get('/users/get_users_page_data')
      res.data.users.map(function(user) {
        user.emails = user.emails !== null ? user.emails.join(', ') : ''
        user.phones = user.phones !== null ? user.phones.join(', ') : ''
      })
      this.context.commit('setUsers', res.data.users)
      this.context.commit('setUserGroups', res.data.groups)
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async logout() {
    await axios.get('/login/do_logout')
  }

  @Action({ rawError: true }) async saveUser(user: Payload) {
    try {
      global.commit('GlobalModule/setLoading', true)

      const formdata = new FormData()
      let groupId = user.group
      let password = ''
      let imageId = user.image_id ? user.image_id : 0

      if (typeof groupId === 'object') {
        groupId = user.group.group_id
      }
      if (user.newPassword) {
        password = user.newPassword
      }
      if (user.newImage) {
        imageId = user.newImage
      }

      const emails = user.emails.replaceAll(' ', '').split(',')
      const phones = user.phones.replaceAll(' ', '').split(',')

      formdata.append('group_id', groupId)
      formdata.append('image_id', imageId)
      formdata.append('login', user.login)
      formdata.append('firstname', user.firstname)
      formdata.append('lastname', user.lastname)
      formdata.append('middlename', user.middlename)
      formdata.append('emails', JSON.stringify(emails))
      formdata.append('phones', JSON.stringify(phones))
      formdata.append('password', password)

      if (user?.user_id) {
        formdata.append('user_id', user.user_id)
        const res = await axios.post('/users/do_edit', formdata)
        if (res.data.error) {
          throw new Error(res.data.error)
        }
      } else {
        formdata.append('user_id', '0')
        const res = await axios.post('/users/do_create', formdata)
        if (res.data.error) {
          throw new Error(res.data.error)
        }
      }
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async requestUsers() {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get('/users/get_all')
      this.context.commit('setUsers', res.data.users)
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async requestUserDelete(id: number) {
    try {
      const formdata = new FormData()
      formdata.append('id', id.toString())
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.post('/users/do_delete', formdata)
      if (res.data.error) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async getRatingUser(payload: Payload) {
    try {
      console.log(payload.month)
      const formdata = new FormData()
      formdata.append('id', payload.id.toString())
      formdata.append('month', payload.month)
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.post('/rating/get_rating_user', formdata)
      if (res.data.error) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)

      return res.data
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async saveUserGroup(group: Payload) {
    try {
      global.commit('GlobalModule/setLoading', true)

      const formdata = new FormData()
      formdata.append('title', group.title)

      if (group?.group_id) {
        formdata.append('group_id', group.group_id)
        const res = await axios.post('/users_groups/do_edit', formdata)
        if (res.data.error) {
          throw new Error(res.data.error)
        }
      } else {
        formdata.append('group_id', '0')
        const res = await axios.post('/users_groups/do_create', formdata)
        if (res.data.error) {
          throw new Error(res.data.error)
        }
      }
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async requestUserGroupDelete(id: number) {
    try {
      const formdata = new FormData()
      formdata.append('id', id.toString())
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.post('/users_groups/do_delete', formdata)
      if (res.data.error) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async checkUser() {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get('/login/do_check_login')
      if (!res.data.error) {
        const user = res.data.user
        this.context.commit('setUserInfo', user)
      } else {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async createComment(payload: Payload) {
    try {
      global.commit('GlobalModule/setLoading', true)

      const formdata = new FormData()
      formdata.append('comment', JSON.stringify(payload.comment))
      formdata.append('essence_id', payload.id)
      formdata.append('essence', payload.essence)

      const res = await axios.post('/comments/do_create_comment', formdata)

      if (!res.data.success) {
        throw new Error(res.data.error)
      }

      global.commit('GlobalModule/setLoading', false)
      return res.data
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async deleteComment(payload: string | number) {
    try {
      global.commit('GlobalModule/setLoading', true)

      const id = String(payload)
      const formdata = new FormData()
      formdata.append('comment_id', id)

      const res = await axios.post('/comments/do_delete_comment', formdata)

      if (!res.data.success) {
        throw new Error(res.data.error)
      }

      global.commit('GlobalModule/setLoading', false)
      return res.data
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }
}
