import { Route, MenuList } from 'vue/interfaces'

export default function createMenuList(routes: Route[]) {
  const menu: MenuList = {
    categories: [],
    links: []
  }

  routes.forEach((item) => {
    if (item.categories) {
      item.categories.forEach((category) => {
        menu.categories.push(category)
      })
    }

    if (item.children) {
      item.children.forEach((child) => {
        if (child.menu !== false) {
          menu.links.push({
            icon: child.icon ?? '',
            text: child.title ?? '',
            href: child.path,
            categoryId: child.categoryId ?? 0
          })
        }
      })
    }
  })
  return menu
}
