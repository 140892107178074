











import { Vue, Component } from 'vue-class-decorator'
import { SchedulesModule } from '@/store'

@Component({
  components: {
    GlobalLoader: () => import('@/components/loaders/GlobalLoader.vue')
  }
})
export default class Bootstrap extends Vue {
  @SchedulesModule.Action('saveActivity') pushActivity!: () => Promise<void>;

  async createActivity() {
    await this.pushActivity().catch((error: any) => {
      // this.$noty('error', error.message, 3000).show()
      console.error(error)
    })
  }

  logOnClose() {
    return navigator.sendBeacon('/api/users_activities/do_create')
  }

  mounted() {
    // Обновление активности каждые 15 минут
    setInterval(() => {
      this.createActivity()
    }, 480000)
    // Обновление активности при закрытии вкладки
    window.addEventListener('unload', () => {
      try {
        this.logOnClose()
      } catch (error) {
        console.error(error)
      }
    })
  }
}
