import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from './routes'
import axios from '@/plugins/axios'

import createMenuList from './menu'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  menuList: createMenuList(routes)
} as { [propName: string]: any })

router.beforeEach(async(to, from, next) => {
  if (to.path === '/login' || to.path === '/404' || to.path === '/403') {
    next()
  } else {
    await axios.get('/access_rights/do_check_route?to=' + to.path)
      .then((res: any) => {
        if (res.data.user) {
          next()
        } else {
          next(res.data.next)
        }
      })
  }
})

export default router
