import Vue from 'vue'
import Vuex from 'vuex'
import { namespace } from 'vuex-class'

Vue.use(Vuex)

class VuexStore {
  private static store = new Vuex.Store({})

  static add(name: string) {
    VuexStore.get().registerModule(name, require(`./modules/${name}`).default)
    return namespace(name)
  }

  static get() {
    return VuexStore.store
  }
}

export default VuexStore.get()

export const GlobalModule = VuexStore.add('GlobalModule')
export const UserModule = VuexStore.add('UserModule')
export const SettingsModule = VuexStore.add('SettingsModule')
export const AccessRightsModule = VuexStore.add('AccessRightsModule')
export const PricesModule = VuexStore.add('PricesModule')
export const AdsModule = VuexStore.add('AdsModule')
export const ClientsModule = VuexStore.add('ClientsModule')
export const SchedulesModule = VuexStore.add('SchedulesModule')
export const CallsModule = VuexStore.add('CallsModule')
