interface ErrorObjectInterface {
  name: any;
  message: any;
  stack: string | null;
  data: any;
}

export class ErrorObject implements ErrorObjectInterface {
  name = 'ErrorObject'
  message = null
  stack = null
  data = null

  constructor(data: any) {
    this.stack = (new Error()).stack as any
    this.data = data
  }
}

ErrorObject.prototype = Object.create(Error.prototype)
ErrorObject.prototype.constructor = ErrorObject
