import { VuexModule, Module, Action, Mutation } from 'vuex-module-decorators'
import { Payload } from 'vue/interfaces'

import global from '@/store'
import axios from '@/plugins/axios'

@Module({ namespaced: true })
export default class SchedulesModule extends VuexModule {
  public userList: Array<object> = []
  public userGroups: Array<object> = []
  public schedules: Array<object> = []
  public lateness: Array<object> = []
  public daysOff: Array<object> = []
  public settings: Array<object> = []

  get getSettings() {
    return this.settings
  }

  get getUsers() {
    return this.userList
  }

  get getUserGroups() {
    return this.userGroups
  }

  get getSchedules() {
    return this.schedules
  }

  get getLateness() {
    return this.lateness
  }

  get getDaysOff() {
    return this.daysOff
  }

  // Mutation
  @Mutation setSettings(payload: Array<object>) {
    this.settings = payload
  }

  @Mutation setUsers(payload: Array<object>) {
    this.userList = payload
  }

  @Mutation setUserGroups(payload: Array<object>) {
    this.userGroups = payload
  }

  @Mutation setSchedules(payload: Array<object>) {
    this.schedules = payload
  }

  @Mutation setLateness(payload: Array<object>) {
    this.lateness = payload
  }

  @Mutation setDaysOff(payload: Array<object>) {
    this.daysOff = payload
  }

  @Action({ rawError: true }) async requestLateness(payload) {
    try {
      global.commit('GlobalModule/setLoading', true)
      const formdata = new FormData()
      formdata.append('date_start', payload.dates[0] ?? '')
      formdata.append('date_end', payload.dates[1] ?? '')
      formdata.append('user_id', payload.user_id ?? '')
      const res = await axios.post('/users_activities/get_lateness', formdata)
      this.context.commit('setLateness', res.data.lateness)
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async requestPageData() {
    try {
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.get('/schedules/get_page_data')
      res.data.users.map(function(user) {
        user.emails = user.emails !== null ? user.emails.join(', ') : ''
        user.phones = user.phones !== null ? user.phones.join(', ') : ''
      })
      this.context.commit('setUsers', res.data.users)
      this.context.commit('setUserGroups', res.data.groups)
      this.context.commit('setSchedules', res.data.schedules)
      this.context.commit('setDaysOff', res.data.days_off)
      this.context.commit('setSettings', res.data.settings)
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async saveDaysOff(daysOff: Payload) {
    try {
      global.commit('GlobalModule/setLoading', true)

      const formdata = new FormData()
      formdata.append('user_id', daysOff.user_id ?? '')
      formdata.append('reason', daysOff.reason ?? '')
      formdata.append('comment', daysOff.comment ?? '')
      formdata.append('dates', JSON.stringify(daysOff.dates) ?? '')

      if (daysOff?.days_off_id) {
        formdata.append('days_off_id', daysOff.days_off_id)
        const res = await axios.post('/schedules/do_edit_days_off', formdata)
        if (res.data.error) {
          throw new Error(res.data.error)
        }
      } else {
        formdata.append('days_off_id', '0')
        const res = await axios.post('/schedules/do_create_days_off', formdata)
        if (res.data.error) {
          throw new Error(res.data.error)
        }
      }
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async requestDeleteDaysOff(id: number) {
    try {
      const formdata = new FormData()
      formdata.append('id', id.toString())
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.post('/schedules/do_delete_days_off', formdata)
      if (res.data.error) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async saveSchedule(schedule: Payload) {
    try {
      global.commit('GlobalModule/setLoading', true)

      const formdata = new FormData()
      formdata.append('user_id', schedule.user_id ?? '')
      formdata.append('group_id', schedule.group_id ?? '')
      formdata.append('schedule', JSON.stringify(schedule.schedule) ?? '')

      if (schedule?.schedule_id) {
        formdata.append('schedule_id', schedule.schedule_id)
        const res = await axios.post('/schedules/do_edit', formdata)
        if (res.data.error) {
          throw new Error(res.data.error)
        }
      } else {
        formdata.append('schedule_id', '0')
        const res = await axios.post('/schedules/do_create', formdata)
        if (res.data.error) {
          throw new Error(res.data.error)
        }
      }
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async requestDelete(id: number) {
    try {
      const formdata = new FormData()
      formdata.append('id', id.toString())
      global.commit('GlobalModule/setLoading', true)
      const res = await axios.post('/schedules/do_delete', formdata)
      if (res.data.error) {
        throw new Error(res.data.error)
      }
      global.commit('GlobalModule/setLoading', false)
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }

  @Action({ rawError: true }) async saveActivity() {
    try {
      global.commit('GlobalModule/setLoading', true)

      await axios.get('/users_activities/do_create')

      global.commit('GlobalModule/setLoading', false)

      return
    } catch (error) {
      global.commit('GlobalModule/setLoading', false)
      throw error
    }
  }
}
